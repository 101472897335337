'use client';

import { Button } from '@nextui-org/button';
import { useEffect } from 'react';
import { IoReloadCircle } from 'react-icons/io5';

export default function Error({
  error,
  reset,
}: {
  error: any;
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    /* eslint-disable no-console */
    console.log(error);
  }, [error]);

  return (
    <body className="select-none">
      <div className="bg-gray-100 px-2 text-center">
        <div className="flex h-screen flex-col items-center justify-center gap-4">
          <h1 className="text-8xl font-extrabold text-red-500">أستغفر الله</h1>
          <p className="text-4xl font-medium text-gray-800">هناك خطأ ما</p>
          <div>
            <Button
              color="success"
              size="sm"
              startContent={<IoReloadCircle size={24} />}
              onPress={reset}
            >
              Rerender
            </Button>
          </div>
        </div>
      </div>
    </body>
  );
}
